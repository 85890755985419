<template>
    <div>
        <clearable-filter-header
            class="mb-6 flex items-center space-x-2"
            filter-key="budget"
            @filter-cleared="resetFilters"
            link-text="remove filter"
            >Group size &amp; budget</clearable-filter-header
        >

        <div class="flex flex-col gap-y-4 text-sm">
            <div class="flex flex-col gap-y-2">
                <label for="modal-filter-budget-group_size"
                    >Approximately how many guests</label
                >

                <div class="flex flex-col gap-y-0.5">
                    <input
                        type="number"
                        name="group_size"
                        min="1"
                        id="modal-filter-budget-group_size"
                        class="block w-full max-w-[330px] rounded-md border-gray-300 py-3 text-base font-normal at768:text-sm"
                        :class="{
                            'border-red-600 focus:border-red-500':
                                needsGroupSize,
                        }"
                        placeholder="Add your group size"
                        v-model="groupSize"
                    />
                    <div
                        :class="{ '!visible': needsGroupSize }"
                        class="invisible text-xs italic text-red-600"
                    >
                        Please add a group size greater than 0
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-y-2">
                <label>Budget type</label>
                <div class="mb-4 flex items-center gap-x-4">
                    <div class="flex items-center gap-x-2">
                        <input
                            type="radio"
                            name="budget-type"
                            id="type-per-person"
                            value="per_person"
                            v-model="budgetType"
                        />
                        <label for="type-per-person">Per person cost</label>
                    </div>
                    <div class="flex items-center gap-x-2">
                        <input
                            type="radio"
                            name="budget-type"
                            id="type-total"
                            value="total"
                            v-model="budgetType"
                        />
                        <label for="type-total">Total event cost</label>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-y-2">
                <label>Price range</label>
                <div class="flex flex-row items-center gap-x-4">
                    <span
                        class="price-input-wrapper relative inline-block w-full"
                    >
                        <input
                            type="number"
                            name="min_price"
                            :min="minPricePerPerson"
                            id="modal-filter-budget-min"
                            class="block w-full rounded-md border-gray-300 pb-3 pl-6 pt-6 text-base font-normal at768:text-sm"
                            v-model="budget[0]"
                            @blur="validateMinPriceInput"
                        />
                        <label
                            for="modal-filter-budget-min"
                            class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                            >Min price</label
                        >
                    </span>
                    <span
                        class="price-input-wrapper relative inline-block w-full"
                    >
                        <input
                            type="number"
                            name="max_price"
                            :min="budget[0]"
                            :max="maxPricePerPerson"
                            id="modal-filter-budget-max"
                            class="block w-full rounded-md border-gray-300 pb-3 pl-6 pt-6 text-base font-normal at768:text-sm"
                            placeholder="Max price"
                            v-model="budget[1]"
                            @blur="validateMaxPriceInput"
                        />
                        <label
                            for="modal-filter-budget-max"
                            class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm !font-normal text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
                            >Max price</label
                        >
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useBudgetFilter } from '@/composables/useBudgetFilter';
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ClearableFilterHeader from 'components/ProductList/ProductFilter/ModalFilters/ClearableFilterHeader.vue';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { computed, ref, watch } from 'vue';

const { queueFilter } = useCollectionFilters();

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const {
    groupSize,
    budget,
    budgetType,
    validateMinPriceInput,
    validateMaxPriceInput,
    minPricePerPerson,
    maxPricePerPerson,
} = useBudgetFilter(
    props.current ? parseInt(props.current[0]) || null : null,
    props.current ? [props.current[1], props.current[2]] : null,
    props.current ? props.current[3] || null : null
);

const isBudgetChanged = computed(() => {
    return (
        budget.value[0] !== minPricePerPerson ||
        budget.value[1] !== maxPricePerPerson
    );
});

const needsGroupSize = ref(false);
const needsBudgetValue = ref(false);

const resetFilters = () => {
    groupSize.value = null;
    budget.value = [minPricePerPerson, maxPricePerPerson];
};

watch(
    [groupSize, budget, budgetType],
    ([newSizeValue, newBudgetValue, newBudgetType]) => {
        let filterData = null;
        const budgetData = omitBy(budget.value, isNull);
        if (
            !isNaN(parseInt(newSizeValue)) &&
            newSizeValue > 0 &&
            Object.keys(budgetData).length === 2
        ) {
            needsGroupSize.value = needsBudgetValue.value = false;
            filterData = [groupSize.value, ...budget.value, budgetType.value];
        } else if (isBudgetChanged.value && !groupSize.value) {
            needsGroupSize.value = true;
        } else if (isBudgetChanged.value && budgetData.length < 2) {
            needsBudgetValue.value = true;
        }

        queueFilter('budget', filterData);
    },
    { deep: true }
);
</script>

<style scoped>
label {
    @apply font-medium;
}
span.price-input-wrapper:before {
    content: '$';
    position: absolute;
    top: 43%;
    left: 10px;
    z-index: 1;
    @apply font-medium;
}
</style>
